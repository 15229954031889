export default {

    billDetail_00001: "Order center",
    billDetail_00002: "Charging order detail",
    billDetail_00003: "All orders",
    billDetail_00004: "Notify car park",
    billDetail_00005: "Order information",
    billDetail_00006: "EV&battery information",
    billDetail_00007: "Charging information",
    billDetail_00008: "Tariff standard",
    billDetail_00009: "Order Track",
    billDetail_00010: "Billing details",
    billDetail_00011: "EV identification code",
    billDetail_00012: "Battery identification code",
    billDetail_00013: "Battery manufacture",
    billDetail_00014: "Battery type",
    billDetail_00015: "Maximum permissible battery temperature",
    billDetail_00016: "Maximum permissible battery temperature",
    billDetail_00017: "Maximum permissible battery voltage",
    billDetail_00018: "Maximum permissible total battery charge voltage",
    billDetail_00019: "Maximum permissible battery current",
    billDetail_00020: "EV battery voltage",
    billDetail_00021: "Rated voltage of the complete EV battery",
    billDetail_00022: "Rated energy of the complete EV battery",
    billDetail_00023: "Rated capacity of the complete EV battery",
    billDetail_00024: "Charging curve comparison",
    billDetail_00025: "Charging curve",
    billDetail_00026: "Demand voltage at the EV end (dashed line)",
    billDetail_00027: "Demand current at the EV end (dashed line)",
    billDetail_00028: "Measured voltage at the EV end (dashed line)",
    billDetail_00029: "Measured current at the EV end (dashed line)",
    billDetail_00030: "Initial battery charge",
    billDetail_00031: "Current battery charge",
    billDetail_00032: "Instantaneous power",
    billDetail_00033: "Average power",
    billDetail_00034: "Largest power",
    billDetail_00035: "EV model",
    billDetail_00036: "Power",
    billDetail_00037: "Current",
    billDetail_00038: "Voltage",
    billDetail_00039: "Battery capacity",
    billDetail_00040: "Maximum power range, power range {powerBegin}-{powerEnd}Kw, total {minutes} minutes (of total {timePercent}), total {powerCharged} kWh, of total power {chargePercent}",
    billDetail_00041: "Accumulated electricity",
    billDetail_00042: "kwh",
    billDetail_00043: "Create charging order",
    billDetail_00044: "Start charging",
    billDetail_00045: "Start billing",
    billDetail_00046: "Stop charging",
    billDetail_00047: "End charging",
    billDetail_00048: "Start charging for postpone time ",
    billDetail_00049: "Complete charging",
    billDetail_00050: "End billing ",
    billDetail_00051: "Payment completed",
    billDetail_00052: "(Emergency stop button pressed, charging stopped)",
    billDetail_00053: "(Equipment failure, charging stopped)",
    billDetail_00054: "(fully charged, or set end condition has been reached)",
    billDetail_00055: "(Device malfunction, end of charge)",
    billDetail_00056: "(Mannual remote end of charge)",
    billDetail_00057: "(end charging by operation at the charging post, e.g. swipe card, press emergency stop, etc.)",
    billDetail_00058: "Current charge rate of the device",
    billDetail_00059: "Deleted",
    billDetail_00060: "Charging amount",
    billDetail_00061: "Total charge (€)",
    billDetail_00062: "Electricity fee (€)",
    billDetail_00063: "Service fee(€)",
    billDetail_00064: "User information",
    billDetail_00065: "Order No.",
    billDetail_00066: "{pay}",
    billDetail_00067: "Start method",
    billDetail_00068: "Payment method",
    billDetail_00069: "Wechat payment",
    billDetail_00070: "WeChat balance payment",
    billDetail_00071: "Group fund account balance payment",
    billDetail_00072: "Third party APP payment",
    billDetail_00073: "User Money Account",
    billDetail_00074: "Offline stored value card payment",
    billDetail_00075: "Online ID card payment",
    billDetail_00076: "WeChat Public (Alipay)",
    billDetail_00077: "Discount Method",
    billDetail_00078: "Get rewarded",
    billDetail_00079: "No coupon used for this order",
    billDetail_00080: "No additional rewards for this order",
    billDetail_00081: "Order notes",
    billDetail_00082: "Please fill in the order notes",
    billDetail_00083: "Are you sure to delete this order comment？",
    billDetail_00084: "Confirm deletion",
    billDetail_00085: "Reminder",
    billDetail_00086: "Day(s)",
    billDetail_00087: "Hour(s)",
    billDetail_00088: "Minute(s)",
    billDetail_00089: "Current order amount",
    billDetail_00090: "Actual order amount",
    billDetail_00091: "Maximum power range, power range {powerBegin}-{powerEnd}Kw, total {minutes} minutes (of total {timePercent}), total {powerCharged} kWh, of total power {chargePercent}",
    billDetail_00092: "Wechat official account（WeChat Pay No-Secret Payment）",
    billDetail_00093: "Wechat official account（Alipay）",
    billDetail_00094: "Book-keeping only",
    billDetail_00095: "No discount on the total cost of a single charge",
    billDetail_00096: "No discount on the total cost of single charge",
    billDetail_00097: "Single service charge {des} discount",
    billDetail_00098: "Total cost of single charge {des} discount",
    billDetail_00099: "Total cost of a single charge is completely free",
    billDetail_00100: "Free single charge service fee",
    billDetail_00101: "Privileges for ranked users",
    billDetail_00102: "Credit {money} € when you pay for the gift",
    billDetail_00103: "Credit {money} € for group allocation amount at time of payment",
    billDetail_00104: "Reason for this order to be paid:",
    billDetail_00105: "The third party system has not been notified that the charging order has been paid.",
    billDetail_00106: "The order amount is greater than the user's account balance at the time due to the late fee.",
    billDetail_00107: "Within the rules of the group allowing a maximum of unpaid.",
    billDetail_00108: "Charging orders are not closed precisely according to the closing conditions (within fault tolerance), resulting in orders slightly larger than the user's account balance at the time",
    billDetail_00109: "The charging post is offline at the time of charging, and the amount of the retroactive order is greater than the user's account balance at that time when it comes online.",
    billDetail_00110: "The payment model for user charging at charging stations is a post-paid model.",
    billDetail_00111: "The order amount is greater than the user's account balance at the time for unknown reasons.",
    billDetail_00112: "Processing of orders pending payment.",
    billDetail_00113: "1. After the account is recharged and the balance is greater than the amount of the order to be paid, the system will automatically settle the order<br/>2、The user will be reminded to pay before the next charge, unpaid orders cannot be charged.<br/>3、f the user has not paid for a long time, you can modify the amount less than the account balance and the system will automatically settle the order.",
    billDetail_00114: "Coupon name",
    billDetail_00115: "Coupon type",
    billDetail_00116: "Using condition",
    billDetail_00117: "Coupon management",
    billDetail_00118: "Deleted",
    billDetail_00119: "Coupon detai;",
    billDetail_00120: "Discount method",
    billDetail_00121: "Use of electricity to offset both electricity and service charges",
    billDetail_00122: "Electricity consumption offset service charge",
    billDetail_00123: "Cash offset electricity and service charges",
    billDetail_00124: "Cash offset service fee",
    billDetail_00125: "Credit {power}kwh for {money}€ off your charge",
    billDetail_00126: "Discount{money}€",
    billDetail_00127: "Electricity ticket",
    billDetail_00128: "Credits",
    billDetail_00129: "start/stop card",
    billDetail_00130: "Value-stored card",
    billDetail_00131: "ID card",
    billDetail_00132: "XCharge configuration card",
    billDetail_00133: "Group configuration card",
    billDetail_00134: "Off-line data reading cards",
    billDetail_00135: "Paid",
    billDetail_00136: "Connected",
    billDetail_00137: "Charging",
    billDetail_00138: "To be paid",
    billDetail_00139: "Billing for postpone",
    billDetail_00140: "Processing stalled orders",
    billDetail_00141: "Temporary cancellation of stalled orders",
    billDetail_00142: "Close and generate billing",
    billDetail_00143: "If the user is unable to continue charging or the charging port cannot be restored to idle due to the order not being billed in time, please choose one of the following options:",
    billDetail_00144: "1. Temporary cancellation: Cancel the order temporarily and wait for the charging station to report the bill later. If the charging post has not been billed for a long time or has been restarted, it is possible that the order will not be billed again after the temporary cancellation and you are advised to use the second method - close and generate a bill.",
    billDetail_00145: "2. Closing and Billing: The order is closed by the operating platform system and a bill is automatically generated for the current charge and attempted to be debited for payment. Please note: Once the bill has been generated, the actual bill reported by the charging post will be ignored.",
    billDetail_00146: "User licence plate number",
    billDetail_00147: "Notification message maintenance",
    billDetail_00148: "The entered plate number cannot be empty",
    billDetail_00149: "Please enter the correct vehicle plate number",
    billDetail_00150: "Third party order No.",
    billDetail_00151: "Retroactive push order",
    billDetail_00152: "Modify price",
    billDetail_00153: "Total price of order",
    billDetail_00154: "Please enter the payment amount",
    billDetail_00155: "Modified amount",
    billDetail_00156: "Discounted amount",
    billDetail_00157: "Discount",
    billDetail_00158: "Third Party Payment",
    billDetail_00159: "Push failure",
    billDetail_00160: "Push success",
    billDetail_00165: "The modified cash amount is not in the correct format",

    // Group administor
    billDetail_00200: "Group name",
    billDetail_00201: "Search order",
    // POS
    billDetail_00202: 'POS machine flow No.',
    billDetail_00203: 'Bank card',
    billDetail_00204: 'Start method',
    billDetail_00205: 'POS user',
    billDetail_00206: 'No idle fee',
    billDetail_00211: "Cancel the order",
    billDetail_00212: "Before canceling the order, if the user has already paid the order, please refund the fees already paid to the user.Are you sure you want to cancel this order? After cancellation, the order data will not be included in the statistics.",
    billDetail_00213: "The meters' NO. when charging starts",
    billDetail_00214: "The meters’ NO. when charging ends",
    billDetail_00215: "Cancelled order",
    bill_00071: "Charging amount is less than 1 kWh",
    bill_00072: "Charging duration is too long",
    bill_00073: "Device malfunction",
    bill_00074: "Car malfunction",
    bill_00101: "Charging amount is more than 1000 kWh",
    bill_00102: "Abnormal charging capacity",
    billDetail_10216: "{username} has modified the order amount {oldFeeTotal}{currencyType} to {feeTotal}{currencyType}.",
    billDetail_10217: "{username} has processed the stalled order and the processing method is the Temporary cancellation.",
    billDetail_10218: "{username} has processed the stalled order and the processing method is the Closing and Billing.",
}